.appoutter {
  background: #d1d1d1;
  position: fixed;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appmodal {
  width: 278px;
  height: 171px;
  padding: 26px 14px 14px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(192, 192, 192, 0.25);
  border-radius: 8px;
  position: relative;
  bottom: 50px;
  align-items: center;
}
.apptitle {
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 12px;
}
.appdesc {
  font-size: 12px;
  color: #777777;
}
.appbutton-group {
  display: flex;
  justify-content: space-around;
  margin-top: 38px;
}
.appbtn {
  height: 40px;
  width: 120px;
  background: #eeeeee;
  color: #555555;
  font-size: 14px;
  letter-spacing: -1px;
  border-radius: 6px;
}
.appopen {
  background: #ff4b19;
  color: #ffffff;
}
