.flexible_contents_box {
  grid-column-gap: 0;
  grid-row-gap: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);

  width: 100%;
}
.row3 {
  grid-template-rows: none;
  min-height: auto;
}
.flexible_contents_box .databox {
  width: 100%;
  height: 100%;
}
.flexible_contents_box .databox:first-child {
  height: 100%;
  border: 0;
}
.flexible_contents_box.row3 .databox:first-child {
  height: auto;
  border: 0;
  overflow: scroll;
  margin-bottom: -9px;
}
.flexible_contents_box.row3 .databox:nth-child(2) {
  margin-top: 0;
}
.flexible_contents_box .databox:nth-child(2) {
  overflow-y: hidden;
  position: relative;
  margin-top: 9px;
}
.flexible_contents_box .databox:nth-child(3) {
  overflow-y: hidden;
  position: relative;
  margin-top: 19px;
}
.scrollbox {
  width: inherit;
}
.row3 .scrollbox {
  height: 100%;
}
.t_garo,
.t_greyth {
  font-size: 14px;
}
.t_greyth tr th:first-child {
  width: 4%;
}
.t_greyth tr th:nth-child(2) {
  width: 4%;
}
.t_greyth tr th:nth-child(3) {
  width: 4%;
}
.t_greyth tr th:nth-child(4) {
  width: 10%;
}
.t_greyth tr th:nth-child(5) {
  width: 10%;
}
.t_greyth tr th:nth-child(7) {
  width: 8%;
}
.t_greyth tr th:nth-child(8) {
  width: 8%;
}
.t_greyth tr th:nth-child(9) {
  width: 5%;
}
.t_greyth tr th:nth-child(10) {
  width: 8%;
}
.t_greyth tr td:nth-child(6) {
  text-align: left;
}
.t_garo {
  word-break: break-all;
  table-layout: auto;
}
.t_garo th {
  width: auto;
  min-width: 100px;
  text-align: right;
  box-sizing: border-box;
  padding: 0px 1% 0px 1%;
}
.t_garo td {
  overflow: hidden;
  text-overflow: unset;
  padding: 0px 5px 0px 5px;
  white-space: normal;
}
.m {
  display: none;
}
.pc {
  display: block;
}
@media screen and (max-width: 1900px) and (min-width: 772px) {
  .t_form .half {
    width: 100%;
  }
}

@media (max-width: 1250px) {
  .flexible_contents_box .scrollbox {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
  }
  .flexible_contents_box .scrollbox .t_greyth,
  .flexible_contents_box .scrollbox .t_garo {
    width: 948px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width: 838px) {
  .m {
    display: block;
  }
  .pc {
    display: none;
  }
  .t_garo th {
    width: 12%;
  }
}
@media (max-width: 771px) {
  .flexible_contents_box {
    height: inherit;
    display: block;
    position: static;
    overflow-y: visible;
  }
  .t_garo,
  .t_greyth {
    font-size: 12px;
  }
  #content {
    height: max-content;
  }
  .flexible_contents_box .databox:first-child {
    position: static;
    height: max-content;
  }
  .flexible_contents_box .databox:first-child .scrollbox {
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow-x: auto;
  }
  .flexible_contents_box .databox:first-child .scrollbox .t_greyth {
    width: 100%;
    position: relative;
  }
  .flexible_contents_box .databox:nth-child(2) {
    border-top: 0;
  }
}
@media (max-width: 562px) {
  .flexible_contents_box .databox:first-child .scrollbox {
    min-height: 25vh;
  }
  .flexible_contents_box .databox:first-child .scrollbox .t_greyth {
    width: 580px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

/*flexible_contents_box2*/

.flexible_contents_box2 {
  /* display: grid; */
  grid-template-columns: 1fr 612px;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;
}
.flexible_contents_box2 .databox:first-child {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}
.flexible_contents_box2 .databox:nth-child(2) {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.flexible_contents_box2 .databox::-webkit-scrollbar-thumb {
  background-color: #d5d6d8;
}
@media (min-width: 1280px) {
  .flexible_contents_box2 .databox:nth-child(2) {
    padding-left: 3px;
    padding-right: 3px;
  }
}

.flexible_contents_box2 .databox .t_garo,
.t_greyth {
  font-size: 14px;
}
.flexible_contents_box2 .databox .t_greyth tr td {
  font-weight: 600;
}
.flexible_contents_box2 .databox .t_greyth tr th:first-child {
  width: 4%;
}
.flexible_contents_box2 .databox .t_greyth tr th:nth-child(2) {
  width: 4%;
}
.flexible_contents_box2 .databox .t_greyth tr th:nth-child(3) {
  width: 4%;
}
.flexible_contents_box2 .databox .t_greyth tr th:nth-child(n + 4) {
  width: auto;
}
.flexible_contents_box2 .databox .t_garo th {
  text-align: right;
  box-sizing: border-box;
  padding: 0px 15px 0px 0px;
}
.flexible_contents_box2 .databox .t_garo td {
  overflow: hidden;
  text-overflow: unset;
  padding: 0px 5px 0px 5px;
}
.flexible_contents_box2 .databox .select_file li h2 {
  font-size: 1.3em;
  line-height: 1em;
  font-weight: 600;
}
.flexible_contents_box2 .databox .select_file li h2 img {
  line-height: 1.3em;
}

@media (max-width: 1430px) {
  .flexible_contents_box2 .databox:nth-child(2) {
    height: 100%;
  }
  .flexible_contents_box2 .databox .scrollbox {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }
  .flexible_contents_box2 .databox .scrollbox .t_greyth {
    width: 800px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width: 1280px) {
  .flexible_contents_box2 {
    display: block;
    position: relative;
    overflow: visible;
  }
  .flexible_contents_box2 .databox .scrollbox {
    height: auto;
    border-top: 1px solid #d5d6d8;
    border-bottom: 1px solid #d5d6d8;
  }
  .flexible_contents_box2 .databox .scrollbox .t_greyth {
    position: relative;
    width: 100%;
  }

  .flexible_contents_box2 .databox:first-child {
    border: 0;
    left: 0;
    top: 9;
    padding-bottom: 9px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 772px) {
  .flexible_contents_box2 .databox .scrollbox {
    overflow-y: auto;
    height: 300px;
  }
  .flexible_contents_box2 .databox .scrollbox .t_greyth {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .flexible_contents_box2 .databox:nth-child(2) {
    padding-left: 0;
  }
}
@media (max-width: 771px) {
  .flexible_contents_box2 {
    display: block;
    position: static;
    overflow-y: visible;
  }
  .flexible_contents_box2 .databox:first-child {
    position: static;
    height: inherit;
  }
  .flexible_contents_box2 .databox:nth-child(2) {
    position: static;
    width: 100%;
    height: auto;
    overflow-y: visible;
  }
  .flexible_contents_box2 .databox .t_garo,
  .t_greyth {
    font-size: 12px;
  }
  .flexible_contents_box2 .databox .scrollbox .t_greyth {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .flexible_contents_box2 .databox .scrollbox {
    position: relative;
    width: 100%;
    max-height: 100%;
    min-height: 250px;
    overflow-x: auto;
  }
  .flexible_contents_box2 .databox .scrollbox .t_greyth {
    width: 666px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width: 415px) {
  .flexible_contents_box2 .databox:first-child {
    overflow-x: auto;
    width: 100%;
  }
}

.bigtitle {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  font-size: 1.3rem;
  width: 670px;
  font-family: "nanumM";
}
.no_action,
.actiont {
  font-family: "nanumM";
}
.no_action {
  width: 670px;
  height: 23px;
  border-collapse: collapse;
  border-image: none;
}
.actiont {
  width: 550px;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 22px;
  border: 1px solid #000;
}
.actiont tr {
  height: 2.5em;
  border: 0 !important;
}
.actiont tr:first-child {
  width: 50%;
}
.actiont tr th,
.actiont tr td {
  font-weight: 400;
  border: 1px solid #000;
  text-align: center;
}
.printwrap {
  margin-top: 45px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }
  /*html, body {
       width: 210mm;
       height: 297mm;
       margin: 0 auto;
  }*/
}

@page {
  size: auto;
  margin: 0;
}

.ck-editor__editable:not(.ck-editor__nested-editable) {
  height: 300px;
}
